import DeleteIcon from '@mui/icons-material/Delete';
import SmsIcon from '@mui/icons-material/Sms';
import { Box, CircularProgress, LinearProgress, Menu, Tooltip, Typography } from '@mui/material';
import { BaseButton, ButtonsCompound, PrimaryButton } from 'components/atoms/Buttons';
import Render from 'components/atoms/Render';
import { MediumText, NormalText, SmallText } from 'components/atoms/Typography';
import ItemQuantityComponent from 'components/molecules/ItemQuantityComponent';
import MyProfileHeader from 'components/MyProfileHeader';
import { db } from 'config/firebase';
import theme from 'core/theme';
import { user } from 'firebase-functions/v1/auth';
import { useAlertDialog } from 'hooks/useAlertDialog';
import { useDataTranslator } from 'hooks/useDataTranslator';
import useLocalStorage from 'hooks/useLocalStorage';
import { Cart, CartItem } from 'klikni-jadi-shared-stuff';
import _, { entries, upperCase } from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { getDetailsRouterArguments } from 'services/application';
import { useCartService } from 'services/cart/useCartService';
import { checkUrl } from 'services/images/utils';
import { stateIsLoading } from 'services/stateHelpers';
import { getCartItemVisiblePrice } from 'services/utils';
import AddComment from './Comment';
import Coupon from './components/Coupon';
import Preorder from './components/Preorder';
import Wallet from './components/Wallet';
import { CartItemListed } from './OrderItems';
import RestaurantSubtotal from './RestaurantSubtotal';
import Roundup from './Roundup';
import emptyCart from 'assets/png/empty-cart.png';

interface ICartItemsProps {
    extraCart?: Cart;
    onAddToGroup?: () => void;
    onlyPreorder?: boolean;
    paymentType?: string;
}

const CartItems = ({ onlyPreorder = false, paymentType }: ICartItemsProps) => {
    const [onlineFee, setOnlineFee] = useState<number>(0);

    const cartState = useSelector((state: RootState) => state.userCart);
    const currentUser = useSelector((state: RootState) => state.currentUser).data;
    const { t } = useTranslation('common');
    const { translate } = useDataTranslator();
    const [cartId] = useLocalStorage('_cart_id');

    const userCart = useMemo(() => cartState.data, [cartState]);

    const cartLoading = useMemo(() => stateIsLoading(cartState), [cartState]);

    // const customersRef = useFirestore('customers');
    // const { isGroupOrder } = useGroupCart();
    const {
        addWallet,
        addPreorder,
        loading,
        changeType,
        loadingType,
        loadingDelivery,
        getFinalCartPrice,
        getUsedWalletMoney,
        loadingPrice,
        count,
    } = useCartService();

    useEffect(() => {
        db.collection('settings')
            .doc('fees')
            .get()
            .then(res => {
                setOnlineFee(res.data()?.onlineFee || 0);
            });
    }, []);

    // const handleAddToGroup = () => {
    //     if (onAddToGroup) onAddToGroup();
    // };
    // useEffect(() => {
    //     customersRef.doc(user.id, currentUserWalletFirestoreActions, { listen: false });
    //     // customersRef.doc('wallet', currentUserFirestoreActions, {
    //     //     listen: false,
    //     //     subcollections: customerSubcollections,
    //     // });
    // }, []);

    const handleReturnWallet = () => {
        addWallet(false);
    };

    const handlePreorderChange = (preorderDate, isPreorder) => {
        addPreorder(preorderDate, isPreorder);
    };

    const handleTypeChange = type => {
        if (!loading && type !== userCart.type) {
            changeType(type);
        }
    };

    return (
        <Box
            sx={{
                minWidth: {
                    xs: '100%',
                    md: 350,
                },
                borderWidth: '1px',
                borderColor: theme.palette.grey[500],
                borderStyle: 'solid',
                borderRadius: '10px',
                overflow: 'hidden',
                // max: '100%',
                maxWidth: {
                    xs: 'unset',
                    sm: '340px',
                },
                width: {
                    xs: '100%',
                    md: '340px',
                },
            }}
            flex={1}
            minHeight={0}
        >
            <Box
                flex={1}
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                minHeight="min-content"
                height="100%"
            >
                <Box flex={1} minHeight={0} overflow="auto">
                    {userCart.isEmpty && (
                        <Box px={2} py={2}>
                            <Typography>{t('yourCartEmpty')}</Typography>
                            <Box py={2}>
                                <img
                                    src={emptyCart}
                                    style={{
                                        width: '100%',
                                        opacity: 0.7,
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                    {!userCart.isEmpty && (
                        <Box>
                            {_.toPairs(userCart.groupedItems)
                                .sort((a, b) => (a[0] > b[0] && 1) || -1)
                                .map(([resId, resItem]) => (
                                    <Box
                                        key={resId}
                                        display="flex"
                                        flexDirection="column"
                                        // sx={{
                                        //     '&:first-child .partner-line-wrapper': {
                                        //         borderRadius: '6px 10px 0 0',
                                        //     },
                                        // }}
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: 'secondary.main',
                                                color: 'primary.main',
                                            }}
                                            display="flex"
                                            justifyContent="space-between"
                                            py={0.5}
                                            px={2}
                                            pb={1}
                                            className="partner-line-wrapper"
                                            mb={1}
                                        >
                                            <Link href={getDetailsRouterArguments(resId, resItem.restaurantName.en)}>
                                                <a>
                                                    <NormalText
                                                        semibold
                                                        className="cursor-pointer"
                                                        sx={{ textDecoration: 'underline' }}
                                                    >
                                                        {translate(resItem.restaurantName)}
                                                    </NormalText>
                                                </a>
                                            </Link>
                                            <Box>
                                                <SmallText
                                                    sx={{
                                                        color: theme => theme.palette.grey[900],
                                                    }}
                                                >
                                                    {t('min')}: {resItem.restaurantMinOrderAmount ?? 400} {t('mkd')}
                                                </SmallText>
                                            </Box>
                                        </Box>
                                        <Box mx={2}>
                                            {_.toPairs(resItem.items)
                                                .sort((a, b) => (a[0] > b[0] && 1) || -1)
                                                .map(([itemId, item], index) => (
                                                    <Box
                                                        display="flex"
                                                        flexDirection="column"
                                                        overflow="auto"
                                                        key={itemId}
                                                        fontSize="0.875rem"
                                                    >
                                                        <CartItemListed
                                                            key={itemId}
                                                            item={item}
                                                            itemId={itemId}
                                                            index={index}
                                                            restaurantId={resId}
                                                            itemsQuantityComponentWidth={90}
                                                            quantityComponentPosition="image"
                                                            imageWidth={90}
                                                            feeType={resItem.feeType}
                                                        />
                                                    </Box>
                                                ))}
                                        </Box>
                                    </Box>
                                ))}

                            {/* {extraCart && (
                                <Box>
                                    <Box px={2} mt={2} mb={1}>
                                        <NormalText semibold>Other users orders</NormalText>
                                    </Box>
                                    {extraCart.itemsByRestaurants.map(resItem => (
                                        <Box key={resItem.restaurant} display="flex" flexDirection="column">
                                            <Box className={classes.restaurantLine} display="flex" justifyContent="center">
                                                <Link href={getDetailsUrl(resItem.restaurantId, resItem.restaurant)}>
                                                    <div>
                                                        <SmallText semibold className="cursor-pointer">
                                                            {resItem.restaurant}
                                                        </SmallText>
                                                    </div>
                                                </Link>
                                            </Box>
                                            {resItem.items.map(item => (
                                                <Box key={item.id} display="flex" className={classes.itemRow} py={1}>
                                                    <Box>
                                                        <img src={item.image} className={classes.itemImage} />
                                                    </Box>
                                                    <Box flex={1} display="flex" flexDirection="column">
                                                        <Box display="flex" flex={1}>
                                                            <Box flex={1} px={1}>
                                                                <NormalText className={classes.itemTitle}>{item.name}</NormalText>
                                                                {(item.excluded.length > 0 && (
                                                                    <Typography className={classes.itemDescription}>
                                                                        Excluded: <b>{item.excluded.join(', ')}</b>
                                                                    </Typography>
                                                                )) || (
                                                                    <Typography className={classes.itemDescription}>
                                                                        All included
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                        <Box display="flex" justifyContent="space-between" alignItems="center">
                                                            <Box ml={1}>
                                                                <SmallerText color="secondary">
                                                                    Ordered by: {item.user.name}
                                                                </SmallerText>
                                                            </Box>
                                                            <NormalText semibold>{item.price} den.</NormalText>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    ))}
                                </Box>
                            )} */}
                        </Box>
                    )}
                </Box>
                {!userCart.isEmpty && (
                    <Box my={2} mx={2}>
                        <Link href="/cart">
                            <a>
                                <PrimaryButton
                                    fullWidth
                                    sx={{
                                        height: 50,
                                        '&>span': {
                                            width: '100%',
                                        },
                                    }}
                                >
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Box display="flex" alignItems="center" gap={1}>
                                            <Box
                                                sx={{
                                                    width: 22,
                                                    height: 22,
                                                    backgroundColor: 'white',
                                                    color: 'primary.main',
                                                    borderRadius: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <NormalText fontWeight="bold">{count}</NormalText>
                                            </Box>
                                            <MediumText fontWeight="bold">{t('goToCart')}</MediumText>
                                        </Box>
                                        <Box>
                                            {loading && (
                                                <Box display="flex" alignItems="center">
                                                    <CircularProgress
                                                        sx={{
                                                            color: 'white',
                                                        }}
                                                        size={20}
                                                    />
                                                </Box>
                                            )}
                                            {!loading && (
                                                <MediumText>
                                                    {userCart.itemsTotalCost} {t('mkd')}
                                                </MediumText>
                                            )}
                                        </Box>
                                    </Box>
                                </PrimaryButton>
                            </a>
                        </Link>
                    </Box>
                )}
            </Box>
        </Box>
    );
};

// interface ICartItemListedProps {
//     item: CartItem;
//     index: number;
//     restaurantId: string;
//     itemId: string;
// }

// const CartItemListed = ({ item, restaurantId, itemId }: ICartItemListedProps) => {
//     const { alert } = useAlertDialog('REMOVE_FROM_CART');
//     const messageRef = useRef<Element>();
//     const [commentOpened, setCommentOpened] = useState<boolean>(false);
//     const { t } = useTranslation('common');
//     const { translate } = useDataTranslator();

//     const { removeItem, addComment, changeQuantity, loadingPrice } = useCartService();

//     const [validImage, setValidImage] = useState<boolean>(false);

//     const cartState = useSelector((state: RootState) => state.userCart);

//     useEffect(() => {
//         checkUrl(item.imageUrl)
//             .then(() => {
//                 setValidImage(true);
//             })
//             .catch(() => {
//                 setValidImage(false);
//             });
//     }, []);

//     const handleRemoveItem = () => {
//         alert(
//             {
//                 title: t('removeItemFromCart'),
//                 description: (
//                     <NormalText>
//                         {t('youAreAboutToRemove')}{' '}
//                         <Typography component="span" variant="body2" color="primary">
//                             <b>{translate(item.name)}</b>
//                         </Typography>{' '}
//                         {t('fromYourCart')}.
//                     </NormalText>
//                 ),
//                 cancelText: t('no'),
//                 successText: t('remove'),
//                 successColor: 'error',
//                 cancelColor: 'primary',
//             },
//             () => {
//                 console.log('itemId: ', itemId);
//                 removeItem(restaurantId, itemId);
//             }
//         );
//     };

//     const handleQuantityChange = quantity => {
//         changeQuantity(restaurantId, itemId, quantity);
//     };

//     const handleCloseCommenting = () => {
//         setCommentOpened(false);
//     };

//     const handleOpenCommenting = () => {
//         setCommentOpened(true);
//     };

//     const handleComment = (comment: string) => {
//         if (cartState.data.groupedItems[restaurantId].items[itemId].comment !== comment) {
//             addComment(restaurantId, itemId, comment);
//         }
//         handleCloseCommenting();
//     };

//     return (
//         <Box
//             key={item.id}
//             display="flex"
//             sx={{
//                 borderBottom: '1px solid #EDEDED',
//                 p: 2,
//             }}
//             py={1}
//         >
//             {validImage && (
//                 <Box>
//                     <Box
//                         component="img"
//                         src={item.imageUrl}
//                         sx={{
//                             width: '70px',
//                             borderRadius: '10px',
//                             height: '50px',
//                             objectFit: 'cover',
//                         }}
//                     />
//                     <ItemQuantityComponent
//                         takeSpace
//                         initial={item.quantity}
//                         onChange={handleQuantityChange}
//                         inCart
//                         disabled={loadingPrice}
//                     />
//                 </Box>
//             )}
//             <Box flex={1} display="flex" flexDirection="column">
//                 <Box display="flex" flex={1} mb={1}>
//                     <Box flex={1} px={1} pl={validImage ? 1 : 0}>
//                         <Box>
//                             <NormalText>{translate(item.name)}</NormalText>
//                         </Box>
//                         {item.selectedPortionId && (
//                             <Box>
//                                 <Typography sx={{ fontSize: '0.9rem', color: '#6D7783' }}>
//                                     {t('portion', { count: 1 })}: <b>{translate(item.portions[item.selectedPortionId].name)}</b>
//                                 </Typography>
//                             </Box>
//                         )}
//                         <Box>
//                             {(item?.excluded?.length > 0 && (
//                                 <Typography sx={{ fontSize: '0.9rem', color: '#6D7783' }}>
//                                     {t('excluded')}: <b>{item.excluded.map(e => translate(e.name)).join(', ')}</b>
//                                 </Typography>
//                             )) || <Typography sx={{ fontSize: '0.9rem', color: '#6D7783' }}>{t('allIncluded')}</Typography>}
//                         </Box>{' '}
//                         {item?.extras?.length > 0 && (
//                             <Box>
//                                 <Typography sx={{ fontSize: '0.9rem', color: '#6D7783' }}>
//                                     {t('extra', { count: 2 })}:{' '}
//                                     <b>{item?.extras?.map(ex => `${ex.quantity} x ${translate(ex.item?.name)}`).join(', ')}</b>
//                                 </Typography>
//                             </Box>
//                         )}
//                     </Box>
//                     <Box>
//                         <Box onClick={handleRemoveItem} style={{ cursor: 'pointer' }}>
//                             <DeleteIcon
//                                 sx={{
//                                     color: '#F42E44',
//                                     cursor: 'pointer',
//                                     fontSize: '1.6em',
//                                 }}
//                             />
//                         </Box>
//                         <>
//                             <Tooltip title={!!item.comment ? <NormalText>{item.comment}</NormalText> : ''}>
//                                 <Box
//                                     style={{ cursor: 'pointer', position: 'relative' }}
//                                     mt={1}
//                                     onClick={handleOpenCommenting}
//                                     ref={messageRef}
//                                 >
//                                     <SmsIcon
//                                         sx={{
//                                             color: 'primary.main',
//                                             cursor: 'pointer',
//                                             fontSize: '1.6em',
//                                         }}
//                                     />
//                                     {item.comment && (
//                                         <Box
//                                             sx={{
//                                                 position: 'absolute',
//                                                 width: 8,
//                                                 height: 8,
//                                                 backgroundColor: 'primary.main',
//                                                 borderRadius: '100%',
//                                                 top: -2,
//                                                 right: -2,
//                                             }}
//                                         ></Box>
//                                     )}
//                                 </Box>
//                             </Tooltip>
//                         </>
//                     </Box>
//                 </Box>
//                 <Box display="flex" justifyContent="space-between" alignItems="center">
//                     <Box>
//                         {!validImage && (
//                             <ItemQuantityComponent
//                                 takeSpace
//                                 initial={item.quantity}
//                                 onChange={handleQuantityChange}
//                                 inCart
//                                 disabled={loadingPrice}
//                             />
//                         )}
//                     </Box>
//                     <NormalText semibold>
//                         {getCartItemVisiblePrice(item, cartState.data.type)} {t('mkd')}.
//                     </NormalText>
//                 </Box>
//             </Box>
//             <Menu anchorEl={messageRef.current} open={commentOpened} onClose={handleCloseCommenting}>
//                 <Box width="100%" px={2} py={1}>
//                     <AddComment rows={2} onDone={handleComment} initialValue={item.comment} />
//                 </Box>
//             </Menu>
//         </Box>
//     );
// };

export default CartItems;
